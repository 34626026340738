<template>
  <v-container class="container-full-size">
    <v-card color="basil" style="margin-top: 0" flat>
      <div v-if="client.showInternalNotes" class="client-pt-notes">
        <div>
          <div>
            <v-textarea v-model="notesFoodPlan" :label="str['nutrition_plan_notes']" :rows="3" hide-details />
          </div>
        </div>
      </div>
      <v-col v-if="showCaloriesFormulas && caloriesFormulas" cols="12">
        <h4>
          {{ str['calories'] }}
        </h4>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="caloriesFormulas.height"
              :label="str['height_cm']"
              required
              oninput="this.value = this.value.replace(/[^0-9]/g, '');"
              hide-details
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="caloriesFormulas.weight"
              :label="str['weight']"
              required
              oninput="this.value = this.value.replace(/[^0-9]/g, '');"
              hide-details
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="caloriesFormulas.age"
              :label="str['age']"
              required
              oninput="this.value = this.value.replace(/[^0-9]/g, '');"
              hide-details
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="caloriesFormulas.activityLevel"
              :label="str['physical_activity_level']"
              item-text="label"
              item-value="value"
              :items="caloriesFormulas.activityLevelItems"
              :no-data-text="str['no_data']"
              hide-details
            />
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <div v-for="(formula, formulaIndex) in caloriesFormulas.getValues()" :key="formulaIndex">
              <b>{{ formula.title }}:</b>
              {{ formula.value }} kCal
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="text-right">
        <v-btn
          v-if="client.showInternalNotes"
          color="secondary"
          :class="{
            'mr-4': hasPermission('nutrition_edit'),
            'mr-0': !hasPermission('nutrition_edit'),
          }"
          @click="client.saveInternalNotes()"
        >
          {{ str['save_notes'] }}
        </v-btn>
        <v-btn v-if="hasPermission('nutrition_edit')" color="success" class="mr-0" @click="openNutrition()">
          {{ str['update_nutrition_plan'] }}
        </v-btn>
      </v-col>
      <v-data-table
        :headers="tableHeaders"
        :items="nutritions"
        :no-data-text="str['nutritions_empty']"
        :no-results-text="str['nutritions_empty']"
        class="elevation-1"
        :loading="isLoading"
        :loading-text="str['processing']"
        @click:row="openNutritionTableRow"
      >
        <template #item.status="{ item }">
          {{ item.id === nutritions[0].id ? str['active'] : '-' }}
        </template>
        <template #item.actions="{ item }">
          <div style="display: flex; justify-content: center">
            <v-icon small color="error" @click.stop="deleteNutrition(item)">mdi-trash-can-outline</v-icon>
          </div>
        </template>
        <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogNutrition" persistent max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['nutrition_plan'] }}
            {{ nutrition && nutrition.date ? '(' + nutrition.date + ')' : '' }}
          </span>
        </v-card-title>

        <v-col v-if="nutrition" cols="12">
          <v-btn color="blue" class="mr-0" @click="openClient()">
            {{ str['client_details'] }}
          </v-btn>
        </v-col>
        <v-col v-if="nutrition" cols="12">
          <v-text-field
            v-model="nutrition.name"
            name="input-7-1"
            :label="str['name']"
            class="purple-input"
            :readonly="!nutrition.current"
          />
          <v-autocomplete
            v-if="user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.show_type"
            v-model="nutrition.type"
            :label="str['type']"
            item-text="label"
            item-value="value"
            :items="nutritionTypes"
            :no-data-text="str['no_data']"
            :readonly="!nutrition.current"
          />
          <v-text-field
            v-if="user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.show_video"
            v-model="nutrition.video_url"
            :label="str['video_url']"
            name="input-7-1"
            class="purple-input"
            :readonly="!nutrition.current"
          />
          <v-textarea
            v-model="nutrition.description"
            :label="str['description']"
            :rows="nutrition.video_url ? 6 : 15"
            :readonly="!nutrition.current"
          />
        </v-col>

        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="dialogNutrition = null">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn v-if="nutrition && nutrition.current && nutrition.base" color="success" @click="saveNutrition(true)">
            {{ str['save'] }}
          </v-btn>
          <v-btn v-if="nutrition && nutrition.current && !nutrition.base" color="success" @click="saveNutrition(true)">
            {{ str['save_with_historic'] }}
          </v-btn>
          <v-btn v-if="nutrition && nutrition.current && !nutrition.base" color="success" @click="saveNutrition()">
            {{ str['save_without_historic'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNewBaseNutrition" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['nutrition_plans_base'] }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <div style="padding: 10px">
          <v-text-field
            v-model="baseNutritionsSearchValue"
            append-icon="mdi-magnify"
            :label="
              user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.show_calories
                ? str['search_by_calories']
                : str['search']
            "
            single-line
            hide-details
          />
        </div>
        <v-card-text style="height: 300px; padding: 10px 0">
          <div
            v-for="baseNutrition in baseNutritions"
            v-show="baseNutritionFiltered(baseNutrition)"
            :key="baseNutrition.id"
            class="box-select-item"
            @click="confirmBaseNutrition(baseNutrition)"
          >
            {{ baseNutrition.name
            }}{{
              user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.show_calories
                ? ' (' + baseNutrition.calories + ' ' + str['calories'] + ')'
                : ''
            }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn color="default" @click="dialogNewBaseNutrition = false">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" style="margin-right: 0" @click="confirmBaseNutrition()">
            {{ str['no_use_base'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <client-feedback ref="feedback" :client="client" :mounted-callback="clientFeedbackMounted" />
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  name: 'ClientNutritionPlan',
  components: {
    ClientFeedback: () => import('@/components/dashboard/client/Feedback'),
  },
  props: {
    client: Object,
  },
  data() {
    const user = Utils.getUser()

    let showCaloriesFormulas = false
    if (
      user &&
      user.configurations &&
      user.configurations.nutrition &&
      user.configurations.nutrition.show_calories &&
      user.configurations.nutrition.formulas
    ) {
      showCaloriesFormulas = true
    }

    return {
      str: window.strings,
      hasPermission: Utils.hasPermission,
      user: user,
      isLoading: false,
      showCaloriesFormulas: showCaloriesFormulas,
      feedbackRef: null,
      tableHeaders: [
        { text: window.strings['id'], value: 'id' },
        { text: window.strings['title'], value: 'name', align: 'center' },
        { text: window.strings['date'], value: 'date', align: 'center' },
        { text: window.strings['status'], value: 'status', align: 'center' },
        {
          text: window.strings['actions'],
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
      nutritionTypes: Utils.getNutritionTypes(),
      dialogNewBaseNutrition: false,
      baseNutritions: [],
      dialogNutrition: false,
      nutrition: null,
      nutritions: [],
      baseNutritionsSearchValue: '',
      caloriesFormulas: null,
    }
  },
  computed: {
    notesFoodPlan: {
      get() {
        return this.client.internalNotes.food_plan
      },
      set(value) {
        this.client.setInternalNotes('food_plan', value)
      },
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.getNutritions()
    this.getHeightAndWeight()
  },
  methods: {
    getNutritions: function (callback) {
      const self = this
      const nutritionConfiguration =
        this.user.configurations && this.user.configurations.nutrition ? this.user.configurations.nutrition : false
      let filtered = true
      if (nutritionConfiguration && nutritionConfiguration.show_type) {
        filtered = false
      }
      this.isLoading = true
      Api.getNutritionPlans(
        {
          id: this.client.dbId,
          filtered: filtered,
          fields: ['id', 'name', 'date', 'status', 'type'],
        },
        function (response) {
          self.isLoading = false
          if (response.success) {
            self.nutritions = response.data
          }
          if (callback) {
            callback()
          }
        },
      )
    },
    getHeightAndWeight: function () {
      const self = this
      Api.getLastPhysicalEvaluation(
        {
          id: this.client.dbId,
          useCache: true,
        },
        function (response) {
          if (response.success) {
            self.handleCaloriesFormulas(response.data[0])
          }
        },
      )
    },
    handleCaloriesFormulas: function (src) {
      const self = this
      if (this.showCaloriesFormulas) {
        try {
          let height = 0
          let weight = 0
          if (src) {
            height = src.body.height
            weight = src.body.weight
          }
          this.caloriesFormulas = {
            height: height,
            weight: weight,
            activityLevel: this.user.configurations.nutrition.formulas.activity_levels[0].value,
            activityLevelItems: this.user.configurations.nutrition.formulas.activity_levels,
            age: this.client.age,
            getValues: function () {
              const formulasValues = []
              try {
                if (self.caloriesFormulas) {
                  const formulas = self.user.configurations.nutrition.formulas[self.client.gender]
                  if (formulas) {
                    formulas.forEach(function (f) {
                      if (
                        parseInt(self.caloriesFormulas.height) &&
                        parseInt(self.caloriesFormulas.weight) &&
                        parseInt(self.caloriesFormulas.age)
                      ) {
                        let formula = f.formula.replace(/<HEIGHT>/g, self.caloriesFormulas.height)
                        formula = formula.replace(/<WEIGHT>/g, self.caloriesFormulas.weight)
                        formula = formula.replace(/<AGE>/g, self.caloriesFormulas.age)
                        formula = formula.replace(/<ACTIVITY_LEVEL>/g, self.caloriesFormulas.activityLevel)
                        formula = formula.replace(/,/g, '.')
                        formulasValues.push({
                          title: f.title,
                          value: new Function('return ' + formula)().toFixed(2),
                        })
                      } else {
                        formulasValues.push({
                          title: f.title,
                          value: window.strings['n_a'],
                        })
                      }
                    })
                  }
                }
              } catch {}
              return formulasValues
            },
          }
        } catch {}
      }
    },
    openNutritionTableRow: function (item) {
      this.openNutrition(item)
    },
    openNutrition: function (item, useBase) {
      const self = this

      if (!item && !useBase && this.user.configurations.menu_items.indexOf('nutrition_base')) {
        return this.openBaseNutritions()
      }

      if (item) {
        this.$isLoading(true)
        Api.getNutritionPlan(
          {
            client_id: useBase ? null : this.client.dbId,
            id: item.id,
          },
          function (response) {
            self.$isLoading(false)
            if (response.success) {
              open(response.data[0])
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } else {
        open(item)
      }

      function open(nutrition) {
        if (!useBase && ((nutrition && nutrition.id !== self.nutritions[0].id) || !self.hasPermission('nutrition_edit'))) {
          if (self.showNutritionLink && typeof nutrition.extra_data === 'string') {
            if (nutrition.extra_data) {
              nutrition.extra_data = JSON.parse(nutrition.extra_data)
            } else {
              nutrition.extra_data = {
                url: '',
                description: '',
              }
            }
          }
          self.nutrition = nutrition
        } else {
          self.dialogNewBaseNutrition = false
          const nutritionData = nutrition ? nutrition : self.nutritions[0]

          if (useBase && nutrition) {
            self.nutrition = {
              id: nutritionData ? nutritionData.id : null,
              name: nutrition.name,
              description: nutrition.description,
              date: nutritionData ? nutritionData.date : '',
              video_url: nutrition.video_url,
              calories: nutrition.calories,
              type: nutritionData ? nutritionData.type : '',
              current: true,
            }
          } else {
            self.nutrition = {
              id: nutritionData ? nutritionData.id : null,
              name: nutritionData ? nutritionData.name : '',
              description: nutritionData ? nutritionData.description : '',
              date: nutritionData ? nutritionData.date : '',
              video_url: nutritionData ? nutritionData.video_url : '',
              calories: nutritionData ? nutritionData.calories : '',
              type: nutritionData ? nutritionData.type : '',
              current: true,
            }
          }

          if (useBase) {
            self.nutrition.base = 1
          }

          if (self.showNutritionLink) {
            const defaultNutrition = nutritionData ? nutritionData : null
            let extraData = {
              url: '',
              description: '',
            }
            if (defaultNutrition && defaultNutrition.extra_data) {
              if (typeof defaultNutrition.extra_data === 'string') {
                extraData = JSON.parse(defaultNutrition.extra_data)
              } else {
                extraData = defaultNutrition.extra_data
              }
            }
            self.nutrition.extra_data = extraData
          }
        }

        self.dialogNutrition = true
      }
    },
    openBaseNutritions: function () {
      const self = this
      const nutritionConfiguration =
        this.user.configurations && this.user.configurations.nutrition ? this.user.configurations.nutrition : false

      let filtered = true
      if (nutritionConfiguration && nutritionConfiguration.show_type) {
        filtered = false
      }

      this.$isLoading(true)
      Api.getNutritionPlans(
        {
          id: null,
          filtered: filtered,
          fields: ['id', 'calories', 'category', 'date', 'days_count', 'name', 'proposal', 'status', 'type'],
        },
        function (response) {
          self.$isLoading(false)

          let baseNutritions = []
          if (response.success) {
            baseNutritions = response.data
          }

          if (baseNutritions.length) {
            self.baseNutritions = baseNutritions
            self.baseNutritionsSearchValue = ''
            self.dialogNewBaseNutrition = true
          } else {
            self.confirmBaseNutrition()
          }
        },
      )
    },
    baseNutritionFiltered: function (baseNutrition) {
      const nutritionConfiguration =
        this.user.configurations && this.user.configurations.nutrition ? this.user.configurations.nutrition : false
      const searchValue = this.baseNutritionsSearchValue.toLowerCase()

      if (!searchValue) {
        return true
      }

      if (nutritionConfiguration && nutritionConfiguration.show_calories) {
        if (baseNutrition.calories.toString().toLowerCase().indexOf(searchValue) > -1) {
          return true
        }
      } else {
        if (baseNutrition.name.toLowerCase().indexOf(searchValue) > -1) {
          return true
        }
      }

      return false
    },
    confirmBaseNutrition: function (item) {
      const self = this

      if (item && item.id) {
        this.$isLoading(true)
        Api.getNutritionPlan(
          {
            id: item.id,
          },
          function (response) {
            self.$isLoading(false)
            if (response.success) {
              confirm(response.data[0])
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } else {
        confirm(item)
      }

      function confirm(baseNutrition) {
        self.openNutrition(baseNutrition, true)
      }
    },
    saveNutrition: function (saveHistory) {
      const self = this
      const data = this.getNutritionData()
      this.$isLoading(true)

      if (data.id && !saveHistory) {
        Api.editNutritionPlan(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogNutrition = false
            self.feedbackRef.openFeedbackDialog()
            self.getNutritions()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      } else {
        delete data.id
        Api.newNutritionPlan(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogNutrition = false
            self.feedbackRef.openFeedbackDialog()
            self.getNutritions()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    getNutritionData: function () {
      const nutrition = {
        client_id: this.client.dbId,
        name: this.nutrition.name,
        description: this.nutrition.description,
        type: 1,
      }
      const nutritionConfiguration =
        this.user.configurations && this.user.configurations.nutrition ? this.user.configurations.nutrition : false

      if (nutritionConfiguration && nutritionConfiguration.show_video) {
        nutrition.video_url = this.nutrition.video_url
      }

      if (nutritionConfiguration && nutritionConfiguration.show_type) {
        nutrition.type = this.nutrition.type
      }

      if (nutritionConfiguration && nutritionConfiguration.show_calories) {
        nutrition.calories = this.nutrition.calories
      }

      if (this.nutrition && this.nutrition.id) {
        nutrition.id = this.nutrition.id
      }

      if (this.showNutritionLink && this.nutrition.extra_data) {
        if (typeof this.nutrition.extra_data === 'string') {
          nutrition.extra_data = this.nutrition.extra_data
        } else {
          nutrition.extra_data = JSON.stringify(this.nutrition.extra_data)
        }
      }

      return nutrition
    },
    openClient: function () {
      window.clientPageCacheList = null
      const routeData = this.$router.resolve({
        path: '/home/client',
        query: { tab: 'details' },
      })
      localStorage.setItem(
        'open_url_new_page',
        JSON.stringify({
          url: routeData.href,
          data: window.dashboardSessionStorage,
        }),
      )
      window.open(location.origin, '_blank')
    },
    deleteNutrition(nutrition) {
      const self = this
      this.$confirm(
        window.strings['do_you_want_delete_diet'] + (nutrition ? ' "' + nutrition.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteNutritionPlan(
            {
              client_id: this.client.dbId,
              id: nutrition.id,
            },
            function (response) {
              if (response.success) {
                self.getNutritions(function () {
                  self.$isLoading(false)
                })
              } else {
                self.$isLoading(false)
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    clientFeedbackMounted() {
      this.feedbackRef = this.$refs.feedback
    },
  },
}
</script>
